import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { CSSProperties } from 'react';
import Img from 'gatsby-image';

interface HeaderProps {
  style?: CSSProperties;
}

const Header: React.FC<HeaderProps> = (props) => {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "logo.webp" }) {
          childImageSharp {
            fluid(maxWidth: 240, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  );
  return (
    <header style={props.style}>
      <Link to="/">
        <Img
          style={{ display: `block`, margin: `0 auto` }}
          fluid={query.file.childImageSharp.fluid}
          alt="Pro Patinball"
        />
      </Link>
      <h1>Pro Paintball</h1>
      <h2>Paintballing in Carlisle, Cumbria</h2>
    </header>
  );
};

export default Header;

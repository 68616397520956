import { Link } from 'gatsby';
import React from 'react';

const Links: React.FC = () => (
  <div>
    <p>
      Links
      <br />
      <a
        href="https://www.youtube.com/watch?v=Qp0jmhiF_ws&amp;feature=youtu.be"
        target="_blank"
        rel="noopener"
      >
        Promotional Video
      </a>
      <br />
      <Link to="/prices/">Pricelist</Link>
    </p>
  </div>
);

export default Links;

import React from 'react';

const Location: React.FC = () => (
  <>
    <address className="location">
      Pro Paintball
      <br />
      Metal Bridge Wood
      <br />
      Gretna Service Road
      <br />
      Floriston
      <br />
      Carlisle
      <br />
      CA6 4HG
    </address>
    <p>
      <a href="https://goo.gl/maps/SB7vdNDehsv" target="_blank" rel="noopener">
        (View in Google maps)
      </a>
    </p>
  </>
);

export default Location;

import React from 'react';
import Header from './header';
import Links from './links';
import Location from './location';

const Layout: React.FC = ({ children }) => (
  <div className="container">
    <Header
      style={{ gridColumn: `1/4`, gridRow: `1/2`, textAlign: `center` }}
    />
    <main style={{ gridColumn: `2/3`, gridRow: `2/3`, textAlign: `center` }}>
      {children}
    </main>
    <footer style={{ gridColumn: `1/4`, gridRow: `3/4`, textAlign: `center` }}>
      <Location />
      <Links />
    </footer>
  </div>
);

export default Layout;
